import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Container,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import api from "./api";

const Login = () => {
  useEffect(() => {
    console.log("Login Component Rendered ===");
  }, []);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(""); // State to store error messages

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!formData.email || !formData.password) {
  //     setError("Please fill in both fields.");
  //     return;
  //   }

  //   try {
  //     const response = await api.post("/login", {
  //       email: formData.email,
  //       password: formData.password,
  //     });
  //     localStorage.setItem("token", response.data.token);
  //     localStorage.setItem("userId", response.data.user.id);
  //     window.location.href = "/adminhome"; // or use navigate("/adminhome")
  //   } catch (error) {
  //     setError(
  //       error.response?.data?.message || "Login failed. Please try again."
  //     );
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post("/login", {
        email: formData.email,
        password: formData.password,
      });
      console.log("Login successful:", response);

      // Check if the response contains the expected user data and token
      if (response.data && response.data.access_token && response.data.user) {
        //
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("userId", response.data.user.id);
        window.location.href = "/adminhome";
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      if (error.response) {
        console.error("Login error:", error.response);
        alert(error.response.data.message || "Login failed");
      } else if (error.request) {
        console.error("No response received:", error.request);
        alert("No response received from the server. Please try again later.");
      } else {
        console.error("Error:", error.message);
        alert("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div>
      <Header />
      <Container style={{ marginTop: "2rem" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Login
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Our Office
            </Typography>
            <Typography variant="body2">Piassa Seid Yasin Building</Typography>
            <Typography variant="body2">Phone: (251) 9..-..-....</Typography>
            <Typography variant="body2">Email: contact@focus.com</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              onSubmit={handleSubmit}
            >
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Login
              </Button>
              {error && <Typography color="error">{error}</Typography>}{" "}
              {/* Display error */}
            </Box>
            <Link to="/register" style={{ textDecoration: "none" }}>
              Create an account
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default Login;
