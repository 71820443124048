//Main.js

import React from "react";
import "./HomeAdmin.css";
import Header from "./components/HeaderAdmin";
import Nav from "./components/Nav";
import Main from "./components/Main";

const BookAdmin = () => {
  return (
    <div>
      <Header />
      <div class="main-container">
        <Nav />
        <div className="main">
          <div className="searchbar2">
            <input type="text" name="" id="" placeholder="Search" />
            <div className="searchbtn">
              <img
                src="https://media.geeksforgeeks.org/wp-content/uploads/20221210180758/Untitled-design-(28).png"
                className="icn srchicn"
                alt="search-button"
              />
            </div>
          </div>

          <div className="box-container"></div>

          <div className="report-container">
            <div className="report-header">
              <h1 className="recent-Articles"> User </h1>
              <button className="view">View All</button>
            </div>

            <div className="report-body">
              <div className="report-topic-heading">
                <h3 className="t-op">User Name</h3>
                <h3 className="t-op">Role</h3>

                <h3 className="t-op">Status</h3>
              </div>

              <div className="items">
                <div className="item1">
                  <h3 className="t-op-nextlvl">Dr. Hassen </h3>
                  <h3 className="t-op-nextlvl">Manager</h3>

                  <h3 className="t-op-nextlvl label-tag">Active</h3>
                </div>

                <div className="item1">
                  <h3 className="t-op-nextlvl">Seada Abera </h3>
                  <h3 className="t-op-nextlvl">Sale</h3>
                  <h3 className="t-op-nextlvl label-tag">Active</h3>
                </div>

                <div className="item1">
                  <h3 className="t-op-nextlvl">Abebe Kebede </h3>
                  <h3 className="t-op-nextlvl">Admin</h3>
                  <h3 className="t-op-nextlvl label-tag">Active</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookAdmin;
