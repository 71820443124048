import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme, useMediaQuery } from "@mui/material";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Theme and breakpoint for responsiveness
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    { label: "Home", to: "/" },
    { label: "About Us", to: "/aboutus" },
    { label: "Projects", to: "/projects" },
    { label: "Publications", to: "/publications" },
    { label: "Services", to: "/services" },
    { label: "Contact Us", to: "/contactUs" },
    { label: "Login", to: "/login" },
  ];

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#1976d2" }}>
        <Toolbar>
          {/* Logo on the left */}
          <Typography variant="h6" sx={{ flexGrow: isMobile ? 1 : 0 }}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "inherit",
                fontWeight: "bold",
              }}
            >
              Focus
            </Link>
          </Typography>

          {/* Menu */}
          {isMobile ? (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
              sx={{ ml: "auto" }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "flex-end", // Align menu items to the right
              }}
            >
              {menuItems.map((item) => (
                <Box
                  key={item.label}
                  component={Link}
                  to={item.to}
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                    mx: 2,
                    fontWeight: "bold",
                  }}
                >
                  {item.label}
                </Box>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <List sx={{ width: 250 }}>
          {menuItems.map((item) => (
            <ListItem
              button
              component={Link}
              to={item.to}
              key={item.label}
              onClick={toggleDrawer} // Close drawer on selection
            >
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Header;
