import React, { useState, useEffect } from "react";
import "./HomeAdmin.css";
import Header from "./components/HeaderAdmin";
import Nav from "./components/Nav";
import api from "../api";

const ArticleAdmin = () => {
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [books, setBooks] = useState([]);
  const [error, setError] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newArticle, setNewArticle] = useState({
    title: "",
    author: "",
    content: "",
  });

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await api.get(`/articles`);
        setBooks(response.data);
      } catch (err) {
        if (err.response) {
          setError(err.response.data.message || "Error fetching articles.");
        } else {
          setError("An unknown error occurred.");
        }
      }
    };
    fetchArticles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post(`/articles`, newArticle);
      setBooks((prevBooks) => [...prevBooks, response.data]); // Add new article to the list
      setNewArticle({ title: "", author: "", content: "" }); // Reset form
      setIsDialogOpen(false); // Close the dialog
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || "Error adding article.");
    }
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div>
      <Header />
      <div className="main-container">
        <Nav />
        <div className="main">
          <div className="searchbar2">
            <input type="text" placeholder="Search" />
            <div className="searchbtn">
              <img
                src="https://media.geeksforgeeks.org/wp-content/uploads/20221210180758/Untitled-design-(28).png"
                className="icn srchicn"
                alt="search-button"
              />
            </div>
          </div>

          <div className="report-container">
            <button className="view" onClick={handleOpenDialog}>
              Add Articles
            </button>
            <div className="report-header">
              <h1 className="recent-Articles">Articles</h1>
              <button className="view">View All</button>
            </div>

            <div className="report-body">
              <div className="report-topic-heading">
                <h3 className="t-op">Article Title</h3>
                <h3 className="t-op">Authors</h3>
                <h3 className="t-op">Price</h3>
                <h3 className="t-op">Status</h3>
              </div>

              <div className="items">
                {books.length > 0 ? (
                  books.map((book, index) => (
                    <div key={index} className="item1">
                      <h3 className="t-op-nextlvl">{book.name || "No Name"}</h3>
                      <h3 className="t-op-nextlvl">
                        {book.authors_name || "N/A"}
                      </h3>
                      <h3 className="t-op-nextlvl">{book.price || "N/A"}</h3>
                      <h3 className="t-op-nextlvl label-tag">
                        {book.status || "Unknown"}
                      </h3>
                    </div>
                  ))
                ) : (
                  <div>No Articles available</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Dialog for Adding Articles */}
      {isDialogOpen && (
        <div className="overlay" onClick={handleCloseDialog}>
          <div className="dialog" onClick={(e) => e.stopPropagation()}>
            <h3>Add a New Article</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Title:</label>
                <input
                  type="text"
                  placeholder="Article Title"
                  value={newArticle.title}
                  onChange={(e) =>
                    setNewArticle({ ...newArticle, title: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Author:</label>
                <input
                  type="text"
                  placeholder="Author Name"
                  value={newArticle.author}
                  onChange={(e) =>
                    setNewArticle({ ...newArticle, author: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Content:</label>
                <textarea
                  placeholder="Article Content"
                  value={newArticle.content}
                  onChange={(e) =>
                    setNewArticle({ ...newArticle, content: e.target.value })
                  }
                ></textarea>
              </div>
              <div className="form-buttons">
                <button type="button" onClick={handleCloseDialog}>
                  Cancel
                </button>
                <button type="submit">Save</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleAdmin;
