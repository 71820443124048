import React, { useState, useEffect } from "react";
import "./HomeAdmin.css";
import Header from "./components/HeaderAdmin";
import Nav from "./components/Nav";
import api from "../api";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";

const ArticleAdmin = () => {
  const [userId] = useState(localStorage.getItem("userId"));
  const [books, setBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newArticle, setNewArticle] = useState({
    title: "",
    author: "",
    content: "",
  });
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editBook, setEditBook] = useState(null);
  const handleEdit = (book) => {
    setEditBook(book); // Set the book to be edited
    setIsEditDialogOpen(true); // Open the edit dialog
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.put(`/books/${editBook.id}`, editBook); // Update the book via API
      setBooks((prevBooks) =>
        prevBooks.map((book) =>
          book.id === editBook.id ? response.data : book
        )
      ); // Update the local books state
      setIsEditDialogOpen(false); // Close the dialog
      setEditBook(null); // Reset the editBook state
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || "Error updating book.");
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await api.get(`/books`);
        setBooks(response.data);
        setFilteredBooks(response.data); // Initialize filteredBooks with all books
      } catch (err) {
        if (err.response) {
          setError(err.response.data.message || "Error fetching books.");
        } else {
          setError("An unknown error occurred.");
        }
      }
    };
    fetchArticles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post(`/books`, newArticle);
      setBooks((prevBooks) => [...prevBooks, response.data]);
      setFilteredBooks((prevBooks) => [...prevBooks, response.data]); // Add to filteredBooks
      setNewArticle({ title: "", author: "", content: "" });
      setIsDialogOpen(false);
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || "Error adding book.");
    }
  };

  // Columns for DataGrid
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Book Title", width: 300 },
    { field: "authors_name", headerName: "Author", width: 300 },
    { field: "price", headerName: "Price", width: 100 },

    {
      field: "edit",
      headerName: "Edit",
      width: 150,
      renderCell: (params) => (
        <div>
          <Button
            className="t-op-nextlvl label-tag"
            style={{ backgroundColor: "#00b100", color: "#fff" }}
            onClick={() => handleEdit(params.row.id)}
          >
            Edit
          </Button>
        </div>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 150,
      renderCell: (params) => (
        <div>
          <Button
            className="t-op-nextlvl label-tag-red"
            style={{ backgroundColor: "#cb4113", color: "#fff" }}
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      await api.delete(`/books/${id}`);
      setBooks((prevBooks) => prevBooks.filter((book) => book.id !== id));
      setFilteredBooks((prevBooks) =>
        prevBooks.filter((book) => book.id !== id)
      ); // Remove from filteredBooks
    } catch (err) {
      console.error("Error deleting book:", err);
    }
  };

  return (
    <div>
      <Header />
      <div className="main-container">
        <Nav />
        <div className="main">
          {/* Search Bar */}
          <div className="searchbar2"></div>

          <div className="report-container">
            <br />
            <button className="view" onClick={handleOpenDialog}>
              Add Book
            </button>
            <div className="report-header">
              <h1 className="recent-Articles">Books</h1>
            </div>

            {/* DataGrid */}
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={filteredBooks.map((book, index) => ({
                  id: index + 1,
                  ...book,
                }))}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                checkboxSelection
              />
            </div>
          </div>
        </div>
      </div>

      {/* Dialog for Adding Books */}
      {isDialogOpen && (
        <div className="overlay" onClick={handleCloseDialog}>
          <div className="dialog" onClick={(e) => e.stopPropagation()}>
            <h3>Add a New Book</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Title:</label>
                <input
                  type="text"
                  placeholder="Book Title"
                  value={newArticle.title}
                  onChange={(e) =>
                    setNewArticle({ ...newArticle, title: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Author:</label>
                <input
                  type="text"
                  placeholder="Author Name"
                  value={newArticle.author}
                  onChange={(e) =>
                    setNewArticle({ ...newArticle, author: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Content:</label>
                <textarea
                  placeholder="Book Content"
                  value={newArticle.content}
                  onChange={(e) =>
                    setNewArticle({ ...newArticle, content: e.target.value })
                  }
                ></textarea>
              </div>
              <div className="form-buttons">
                <button type="button" onClick={handleCloseDialog}>
                  Cancel
                </button>
                <button type="submit">Save</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/*  */}
      {isEditDialogOpen && editBook && (
        <div className="overlay" onClick={() => setIsEditDialogOpen(false)}>
          <div className="dialog" onClick={(e) => e.stopPropagation()}>
            <h3>Edit Book</h3>
            <form onSubmit={handleSaveEdit}>
              <div className="form-group">
                <label>Title:</label>
                <input
                  type="text"
                  placeholder="Book Title"
                  value={editBook.name || ""}
                  onChange={(e) =>
                    setEditBook({ ...editBook, name: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Author:</label>
                <input
                  type="text"
                  placeholder="Author Name"
                  value={editBook.authors_name || ""}
                  onChange={(e) =>
                    setEditBook({ ...editBook, authors_name: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Price:</label>
                <input
                  type="text"
                  placeholder="Book Price"
                  value={editBook.price || ""}
                  onChange={(e) =>
                    setEditBook({ ...editBook, price: e.target.value })
                  }
                />
              </div>
              <div className="form-buttons">
                <button
                  type="button"
                  onClick={() => setIsEditDialogOpen(false)}
                >
                  Cancel
                </button>
                <button type="submit">Save</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleAdmin;
