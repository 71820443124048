import React, { useState } from "react";
import { Link } from "react-router-dom";
import searchIcon from "../../../images/search.png";
import profileIcon from "../../../images/profile.png";
import bellIcon from "../../../images/bell.png";
const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <header2>
      <div className="logosec">
        <div className="logo">Focus Trading</div>
        <img
          src="https://media.geeksforgeeks.org/wp-content/uploads/20221210182541/Untitled-design-(30).png"
          className="icn menuicn"
          id="menuicn"
          alt="menu-icon"
        />
      </div>

      <div className="searchbar">
        <input type="text" placeholder="Search" />
        <div className="searchbtn">
          <img src={searchIcon} className="icn srchicn" alt="search-button" />
        </div>
      </div>

      <div className="message">
        <div className="circle"></div>
        <img src={bellIcon} className="icn" alt="" />
        <div className="dp" onClick={toggleDropdown}>
          <img src={profileIcon} className="dpicn" alt="dp" />
          {isDropdownOpen && (
            <div className="dropdown">
              <ul>
                <li>
                  {" "}
                  <Link
                    to="/profileadmin"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <h3>Profile </h3>
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="/settingsadmin"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <h3>Settings </h3>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/login"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <h3>Logout </h3>
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header2>
  );
};

export default Header;
