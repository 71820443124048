//Nav.js

import React from "react";
import { Link } from "react-router-dom";
import dashboardIcon from "../../../images/dashboard.png";
import articleIcon from "../../../images/article.png";
import userIcon from "../../../images/users.png";
import financialIcon from "../../../images/financial.png";
const Nav = () => {
  return (
    <div className="navcontainer">
      <nav className="nav">
        <div className="nav-upper-options">
          <div className="nav-option option1">
            <img src={dashboardIcon} className="nav-img" alt="dashboard" />

            <Link
              to="/adminhome"
              style={{
                textDecoration: "none",
                color: "#1976d2",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              <h3 style={{ margin: 0 }}>Dashboard</h3>
            </Link>
          </div>
          <div className="option2 nav-option">
            <img src={articleIcon} className="nav-img" alt="articles" />

            <Link
              to="/bookadmin"
              style={{
                textDecoration: "none",
                color: "#1976d2",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              <h3>Books</h3>
            </Link>
          </div>

          <div className="option2 nav-option">
            <img src={articleIcon} className="nav-img" alt="articles" />

            <Link
              to="/articleadmin"
              style={{
                textDecoration: "none",
                color: "#1976d2",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              <h3>Articles</h3>
            </Link>
          </div>

          <div className="option2 nav-option">
            <img src={articleIcon} className="nav-img" alt="articles" />

            <Link
              to="/lectureadmin"
              style={{
                textDecoration: "none",
                color: "#1976d2",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              <h3>Lecture Notes </h3>
            </Link>
          </div>

          <div className="nav-option option4">
            <img src={userIcon} className="nav-img" alt="users" />

            <Link
              to="/useradmin"
              style={{
                textDecoration: "none",
                color: "#1976d2",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              <h3>Users</h3>
            </Link>
          </div>

          <div className="nav-option option5">
            <img src={financialIcon} className="nav-img" alt="financial" />

            <Link
              to="/financeadmin"
              style={{
                textDecoration: "none",
                color: "#1976d2",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              <h3>Finance</h3>
            </Link>
          </div>
          <div className="nav-option option3">
            <img
              src="https://media.geeksforgeeks.org/wp-content/uploads/20221210183320/5.png"
              className="nav-img"
              alt="report"
            />

            <Link
              to="/reportadmin"
              style={{
                textDecoration: "none",
                color: "#1976d2",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              <h3>Report</h3>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
