//Main.js
import React, { useState, useEffect } from "react";

import "./HomeAdmin.css";
import Header from "./components/HeaderAdmin";
import Nav from "./components/Nav";
import Main from "./components/Main";
import api from "../api";
import { Alert } from "@mui/material";

const BookAdmin = () => {
  const [userId, setUserId] = useState(null);

  const [books, setBooks] = useState([]);
  const [error, setError] = useState("");

  const savedUserId = localStorage.getItem("userId");
  if (!userId && savedUserId) {
    setUserId(savedUserId); // Add logic to fetch user info
  }
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get(`/articles`);
        console.log(response.data);
        // window.alert(JSON.stringify(response.data, null, 2)); // Display response.dat
        setBooks(response.data);
      } catch (err) {
        if (err.response) {
          console.log(err.response);
          setError(err.response.data.message || "Error fetching user data");
        } else {
          setError("An unknown error occurred.");
        }
      }
    };

    fetchUser();
  }, [userId]); // userId is now correctly defined in the scope

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!books) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <Header />
      <div class="main-container">
        <Nav />
        <div className="main">
          <div className="searchbar2">
            <input type="text" name="" id="" placeholder="Search" />
            <div className="searchbtn">
              <img
                src="https://media.geeksforgeeks.org/wp-content/uploads/20221210180758/Untitled-design-(28).png"
                className="icn srchicn"
                alt="search-button"
              />
            </div>
          </div>

          <div className="report-container">
            <br />
            <button className="view">Add </button>
            <div className="report-header">
              <h1 className="recent-Articles">Finance </h1>
              <button className="view">View All</button>
            </div>

            <div className="report-body">
              <div className="report-topic-heading">
                <h3 className="t-op">Finance </h3>
                <h3 className="t-op">INCOME/EXPENSE</h3>
                <h3 className="t-op">AMOUNT</h3>
                <h3 className="t-op">Status</h3>
              </div>

              <div className="items">
                {books.length > 0 ? (
                  books.map((book, index) => (
                    <div key={index} className="item1">
                      <h3 className="t-op-nextlvl">{book.name || "No Name"}</h3>
                      <h3 className="t-op-nextlvl">
                        {book.authors_name || "N/A"}
                      </h3>
                      <h3 className="t-op-nextlvl">{book.price || "N/A"}</h3>
                      <h3 className="t-op-nextlvl label-tag">
                        {book.status || "Unknown"}
                      </h3>
                    </div>
                  ))
                ) : (
                  <div>No Articles available</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookAdmin;
