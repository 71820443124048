import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Projects from "./pages/Projects";
import Publication from "./pages/Publication";
import Services from "./pages/Services";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import AdminHome from "./pages/adminpages/AdminApp";
import BookAdmin from "./pages/adminpages/BookAdmin";
import ArticleAdmin from "./pages/adminpages/ArticleAdmin";
import LectureAdmin from "./pages/adminpages/LectureAdmin";
import UserAdmin from "./pages/adminpages/UserAdmin";
import ReportAdmin from "./pages/adminpages/ReportAdmin";
import FinanceAdmin from "./pages/adminpages/FinanceAdmin";
import ProfileAdmin from "./pages/adminpages/ProfileAdmin";
import SettingsAdmin from "./pages/adminpages/SettingsAdmin";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/publications" element={<Publication />} />
        <Route path="/services" element={<Services />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/adminhome" element={<AdminHome />} />
        <Route path="/bookadmin" element={<BookAdmin />} />
        <Route path="/articleadmin" element={<ArticleAdmin />} />
        <Route path="/lectureadmin" element={<LectureAdmin />} />
        <Route path="/useradmin" element={<UserAdmin />} />
        <Route path="/reportadmin" element={<ReportAdmin />} />
        <Route path="/financeadmin" element={<FinanceAdmin />} />
        <Route path="/profileadmin" element={<ProfileAdmin />} />
        <Route path="/settingsadmin" element={<SettingsAdmin />} />
      </Routes>
    </Router>
  );
}

export default App;
