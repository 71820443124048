//Main.js

import React from "react";
//import searchIcon from "../src/images/search.png"; // Import the local image
import searchIcon from "../../../images/search.png";
import likesIcon from "../../../images/likes.png";
import commentIcon from "../../../images/comments.png";
import eyeIcon from "../../../images/eye.png";
import rightIcon from "../../../images/right.png";

const Main = () => {
  return (
    <div className="main">
      <div className="searchbar2">
        <input type="text" name="" id="" placeholder="Search" />
        <div className="searchbtn">
          <img src={searchIcon} className="icn srchicn" alt="search-button" />
        </div>
      </div>

      <div className="box-container">
        <div className="box box1">
          <div className="text">
            <h2 className="topic-heading">60.5k</h2>
            <h2 className="topic">Article Views</h2>
          </div>
          <img src={eyeIcon} alt="Views" />
        </div>

        <div className="box box2">
          <div className="text">
            <h2 className="topic-heading">150</h2>
            <h2 className="topic">Likes</h2>
          </div>
          <img src={likesIcon} className="icn srchicn" alt="search-button" />
        </div>

        <div className="box box3">
          <div className="text">
            <h2 className="topic-heading">320</h2>
            <h2 className="topic">Comments</h2>
          </div>
          <img src={commentIcon} className="icn srchicn" alt="comment-button" />
        </div>

        <div className="box box4">
          <div className="text">
            <h2 className="topic-heading">70</h2>
            <h2 className="topic">Published</h2>
          </div>
          <img src={rightIcon} alt="published" />
        </div>
      </div>

      <div className="report-container">
        <div className="report-header">
          <h1 className="recent-Articles">Recent activities</h1>
          <button className="view">View All</button>
        </div>

        <div className="report-body">
          <div className="report-topic-heading">
            <h3 className="t-op">Article</h3>
            <h3 className="t-op">Views</h3>
            <h3 className="t-op">Comments</h3>
            <h3 className="t-op">Status</h3>
          </div>

          <div className="items">
            <div className="item1">
              <h3 className="t-op-nextlvl">Article 73</h3>
              <h3 className="t-op-nextlvl">2.9k</h3>
              <h3 className="t-op-nextlvl">210</h3>
              <h3 className="t-op-nextlvl label-tag">Published</h3>
            </div>

            <div className="item1">
              <h3 className="t-op-nextlvl">Article 65</h3>
              <h3 className="t-op-nextlvl">1.3k</h3>
              <h3 className="t-op-nextlvl">220</h3>
              <h3 className="t-op-nextlvl label-tag">Published</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
