import React, { useState, useEffect } from "react";
import "./HomeAdmin.css";
import Header from "./components/HeaderAdmin";
import Nav from "./components/Nav";
import Main from "./components/Main";
import api from "../api";
import searchIcon from "../../images/search.png";

const BookAdmin = () => {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState("");

  const savedUserId = localStorage.getItem("userId");
  if (!userId && savedUserId) {
    setUserId(savedUserId); // Add logic to fetch user info
  }

  //const userId = 1; // Define userId in the component scope

  useEffect(() => {
    const fetchUser = async () => {
      try {
        //const response = await axios.get(`/_users/${userId}`);
        const response = await api.get(`/_users/${savedUserId}`);
        setUser(response.data);
      } catch (err) {
        if (err.response) {
          console.log(err.response);
          setError(err.response.data.message || "Error fetching user data");
        } else {
          setError("An unknown error occurred.");
        }
      }
    };

    fetchUser();
  }, [userId]); // userId is now correctly defined in the scope

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header />
      <div class="main-container">
        <Nav />
        <div className="main">
          <div className="searchbar2">
            <input type="text" name="" id="" placeholder="Search" />
            <div className="searchbtn">
              <img
                src={searchIcon}
                className="icn srchicn"
                alt="search-button"
              />
            </div>
          </div>

          <div className="report-container">
            <div className="report-header">
              <h1 className="recent-Articles">Settings Information </h1>
            </div>

            <p>
              <strong>Name:</strong> {user.name}
            </p>
            <p>
              <strong>Email:</strong> {user.email}
            </p>
            <p>
              <strong>Status:</strong> {user.status ? "Active" : "Inactive"}
            </p>
            {/* Add more fields as needed */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookAdmin;
