//App.js

import React from "react";
import "./HomeAdmin.css";
import Header from "./components/HeaderAdmin";
import Nav from "./components/Nav";
import Main from "./components/Main";

function AdminApp() {
  return (
    <div>
      <Header />
      <div class="main-container">
        <Nav />
        <Main />
      </div>
    </div>
  );
}

export default AdminApp;
